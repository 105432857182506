export function getDisplayNameInitials(displayName) {
    if (displayName) {
        try {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

            let initials = [...displayName.matchAll(rgx)] || [];

            return (
                (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
            ).toUpperCase();
        } catch (e) {}
    }
    return null; // DEFAULT RETURN
}
