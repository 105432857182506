import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavLink,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Avatar, Modal, Box } from "@mui/material";
// DEMO
import bridgewaylogo from "../../assets/logobridge2.png";
import { useAuth } from "../../context/auth-context";
import { getDisplayNameInitials } from "../../helpers";
import useGlobal from "../../context/GlobalContext";

export default function NavigationBar({ version }) {
	const [initials, setInitials] = useState("");
	const [showSupportModal, setShowSupportModal] = useState(false);
	const { displayName } = useAuth();
	const { activeOrganization } = useGlobal();

	const handleOpenModal = () => setShowSupportModal(true);
	const handleCloseModal = () => setShowSupportModal(false);

	useEffect(
		() => setInitials(getDisplayNameInitials(displayName)),
		[displayName]
	);

	// State
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => {
		setDropdownOpen((prevState) => !prevState);
	};

	const [collapsed, setCollapsed] = useState(true);

	const toggleNavbar = () => setCollapsed(!collapsed);

	const { instance } = useMsal();
	const handleLogout = async () => {
		await instance.handleRedirectPromise();
		instance.logoutRedirect();
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		minWidth: 300,
		maxWidth: 500,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
	};

	const SupportModal = () => (
		<Modal
			open={showSupportModal}
			onClose={handleCloseModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div>
					Contact us! Our email is{" "}
					<a href="mailto:analytics@bridgewaybilling.net">
						analytics@bridgewaybilling.net
					</a>
				</div>
				<div>Current Version: {version}</div>
			</Box>
		</Modal>
	);

	return (
		<>
			<Navbar
				style={{
					backgroundColor: "#fff",
					boxShadow: "0 3px 5px -2px #999",
					padding: "7px 15px",
					marginBottom: 10,
				}}
				light
				expand="md"
			>
				<NavbarToggler onClick={toggleNavbar} />
				<img
					className="float-left icons logo-main"
					alt="xantie icon"
					src={bridgewaylogo}
				/>
				<Collapse isOpen={!collapsed} navbar>
					<Nav className="mr-auto" navbar></Nav>

					{activeOrganization ? (
						<NavLink className="nav-text nav-font" tag={Link} to={"/"}>
						Change Organization
					</NavLink>
					): ""}
				</Collapse>
				<Dropdown isOpen={dropdownOpen} toggle={toggle}>
					<DropdownToggle
						tag="span"
						data-toggle="dropdown"
						aria-expanded={dropdownOpen}
					>
						<Avatar style={{ backgroundColor: "#666" }}>
							{initials}
						</Avatar>
					</DropdownToggle>
					<DropdownMenu right style={{ marginRight: 30 }}>
						<DropdownItem disabled>{displayName}</DropdownItem>
						<DropdownItem
							onClick={() => handleLogout()}
							className="text-danger"
						>
							Log out
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem>
							<Link
								to="#"
								className="nav-text-normal"
								onClick={handleOpenModal}
							>
								Support
							</Link>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</Navbar>

			{/* Support modal */}
			<SupportModal />
		</>
	);
}
