import React from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import useGlobal from "../../../context/GlobalContext";

export default function ReportComponent() {
	const { activeReport, setActiveReport } = useGlobal();

	const embededConfig = ({ reportId, embedUrl, accessToken }) => {
		return {
			type: "report", // Supported types: report, dashboard, tile, visual and qna
			id: reportId,
			embedUrl,
			accessToken: accessToken,
			tokenType: models.TokenType.Embed,
			settings: {
				panes: {
					filters: {
						expanded: false,
						visible: false,
					},
				},
				// background: models.BackgroundType.Transparent,
			},
		};
	};

	const handleCloseReport = () => {
		setActiveReport(null);
	};

	return (
		<div className="card my-4">
			<div className="card-body">
				<div className="d-flex justify-content-between">
					<div className="card-title">{activeReport.reportName}</div>
					<span
						className="h4"
						style={{ cursor: "pointer" }}
						onClick={handleCloseReport}
					>
						&times;
					</span>
				</div>
				<PowerBIEmbed
					embedConfig={embededConfig(activeReport)}
					eventHandlers={
						new Map([
							// [
							// 	"loaded",
							// 	function () {
							// 		console.log("Report loaded");
							// 	},
							// ],
							// [
							// 	"rendered",
							// 	function () {
							// 		console.log("Report rendered");
							// 	},
							// ],
							[
								"error",
								function (event) {
									console.log(event.detail);
								},
							],
						])
					}
					cssClassName={"chart-area-selected"}
				/>
			</div>
		</div>
	);
}
