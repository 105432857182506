import React, { useState, useEffect } from "react";

import { InputGroup, Input, InputGroupAddon, InputGroupText } from "reactstrap";
import useReports from "../ReportsContext";

export default function FilterReportsComponent() {
	const { reports, setFilteredReports } = useReports();
	const [searchStr, setSearchStr] = useState("");

	useEffect(() => {
		if (searchStr !== "") {
			let filtered = reports.map((report) => {
				if (report.reportName.toLocaleLowerCase().includes(searchStr)) {
					return { ...report, hiddenFLG: false };
				} else {
					return { ...report, hiddenFLG: true };
				}
			});
			setFilteredReports(filtered);
		} else {
			setFilteredReports(reports);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchStr, reports]);

	return (
		<div className="mb-2" style={{ maxWidth: 350, marginLeft: "auto" }}>
			<InputGroup>
				<Input
					onChange={({ target }) =>
						setSearchStr(target.value.toLocaleLowerCase())
					}
					placeholder="Search Reports"
				/>
				<InputGroupAddon addonType="append">
					<InputGroupText>Search</InputGroupText>
				</InputGroupAddon>
			</InputGroup>
		</div>
	);
}
