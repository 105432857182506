import { createContext, useContext, useEffect, useState } from "react";
import { getOrganizations } from "./organization.service";

const OrganizationContext = createContext();

export default function useOrganization() {
	return useContext(OrganizationContext);
}

export function OrganizationProvider({ children }) {
	const [organizations, setOrganizations] = useState([]);

	useEffect(() => {
		getOrganizations()
			.then((response) => {
				if (response) {
					setOrganizations(response.organizationRows);
				}
				// setembedUrls(mappedResponse);
				// setFilteredTiles(mappedResponse);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<OrganizationContext.Provider value={{ organizations }}>
			{children}
		</OrganizationContext.Provider>
	);
}
