import React from "react";
import useReports from "../ReportsContext";
import ReportTileComponent from "./ReportTile";
export default function ReportsComponent() {
	const { filteredReports, loaded, reportsError } = useReports();

	return (
		<div>
			<div className="d-flex flex-wrap">
				{loaded && filteredReports.length
					? filteredReports.map((report) => (
							<ReportTileComponent
								key={report.reportId}
								report={report}
							/>
					  ))
					: reportsError
					? "An error has occurred."
					: loaded && !filteredReports.length
					? "No reports were found."
					: "Loading reports..."}
			</div>
		</div>
	);
}
