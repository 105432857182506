import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./client/assets/css/main.css";
// Components

// MSAL packages
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

// Local files to be used by MSAL
import { AuthProvider } from "../src/client/context/auth-context";
import { loginRequest } from "../src/client/config/auth-config";
import { msalInstance } from "./client/utils/msal-instance";
import { CustomNavigationClient } from "../src/client/utils/navigation-client";
import PageRouter from "./client/pages";

function App({ instance }) {
	const authRequest = {
		...loginRequest, //{scopes: ["User.Read"]}
	};

	const history = useNavigate();
	const navigationClient = new CustomNavigationClient(history);
	instance.setNavigationClient(navigationClient);

	return (
		<div className="App">
			<MsalProvider instance={instance}>
				<AuthProvider>
					<MsalAuthenticationTemplate
						interactionType={InteractionType.Redirect}
						authenticationRequest={authRequest}
					>
						<PageRouter />
					</MsalAuthenticationTemplate>
				</AuthProvider>
			</MsalProvider>
		</div>
	);
}

ReactDOM.render(
	<Router>
		<App instance={msalInstance} />
	</Router>,
	document.getElementById("root")
);
