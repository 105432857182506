import {
    ReportsComponent,
    FilterReportsComponent,
    ReportComponent,
} from "./Reports";
import { ReportProvider } from "./ReportsContext";

export const components = {
    ReportsComponent,
    FilterReportsComponent,
    ReportComponent,
};

export default ReportProvider;
