import React, { useEffect } from "react";
import useGlobal from "../context/GlobalContext";
import OrganizationProvider, { components } from "../features/organization";

export default function SelectOrganizationPage() {
	const { OrganizationsComponent } = components;

	const { setActiveOrganizationID, setActiveOrganization } = useGlobal();

	useEffect(() => {
		setActiveOrganizationID(null);
		setActiveOrganization(null);
	}, [])
	// window.location.href += "reports";
	return (
		<div className="organizations">
			<OrganizationProvider>
				<OrganizationsComponent />
			</OrganizationProvider>
		</div>
	);
}
