import React, { useContext, useEffect, useState } from "react";
// Azure msal context. Allows us to get the value of inProgress
import { useMsal } from "@azure/msal-react";
// Allows us to check the value of inProgress
import { InteractionStatus } from "@azure/msal-browser";
// An API call to get logged in user information from a token
import { callMsGraph } from "../utils/ms-graph-api-call";

const AuthContext = React.createContext();
// useAuth is a custom hook that will give access all items inside AuthProvider's value object
export function useAuth() {
	return useContext(AuthContext);
}
// React Functional Provider AuthProvider
// Param children: all React components that are children of AuthProvider
// Returns a React context provider for authentication context.
export function AuthProvider({ children }) {
	const { inProgress } = useMsal(); // Is MSAL working on something
	const [graphData, setGraphData] = useState(null); // Where we store the Azure AD account information
	const [displayName, setDisplayName] = useState(null);
	const [username, setUsername] = useState(null);
	const [activeOrganization, setActiveOrganization] = useState(null);

	// If inProgress (inside the dependency array) ever changes value, run this useEffect.
	useEffect(() => {
		// If no interaction is in progress, then fetch the user graph data and update the state of graphData
		// If something goes wrong, console log the issue
		if (inProgress === InteractionStatus.None) {
			callMsGraph()
				.then((response) => {
					setGraphData(response);
					setDisplayName(response.displayName);
					setUsername(response.userPrincipalName);
				})
				.catch((error) => console.log(error.message, "HEREH"));
		}
	}, [inProgress]);

	const value = {
		displayName,
		graphData,
		username,
		activeOrganization,
		setActiveOrganization,
	};

	// Returns a context provider for Authentication. Any child of this component can access everything
	// inside value by using the custom useAuth hook.
	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
}
