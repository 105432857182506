import { createContext, useContext, useEffect, useState } from "react";
import useGlobal from "../../context/GlobalContext";
import { getUserReportsForOrganization } from "./reports.service";

const ReportContext = createContext();

export default function useReports() {
	return useContext(ReportContext);
}

export function ReportProvider({ children }) {
	const { activeOrganizationID } = useGlobal();
	const [reports, setReports] = useState([]);
	const [filteredReports, setFilteredReports] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [reportsError, setReportsError] = useState(false);

	useEffect(() => {
		if (activeOrganizationID) {
			setLoaded(false);
			getUserReportsForOrganization(activeOrganizationID)
				.then((res) => {
					if (res.length) {
						const allReports = [];
						for (const workspace of res) {
							const mappedResponse = workspace.embedUrl.map(
								(url) => ({
									...url,
									accessToken: workspace.accessToken,
								})
							);
							allReports.push(...mappedResponse);
						}

						setReports(allReports);
					}
					setLoaded(true);
				})
				.catch((err) => {
					console.log(err);
					setReportsError(true);
				});
		}
	}, [activeOrganizationID]);

	return (
		<ReportContext.Provider
			value={{ reports, reportsError, filteredReports, loaded, setFilteredReports }}
		>
			{children}
		</ReportContext.Provider>
	);
}
