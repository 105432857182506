import React, { useEffect } from "react";
import useGlobal from "../context/GlobalContext";
import ReportsProvider, { components } from "../features/reports";
import { ReportComponent } from "../features/reports/Reports";
import { useParams } from "react-router-dom";

export default function ViewOrganizationReportsPage() {
	const { activeReport, setActiveOrganizationID } = useGlobal();
	const { OrganizationID } = useParams();

	useEffect(() => {
		setActiveOrganizationID(OrganizationID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { FilterReportsComponent, ReportsComponent } = components;
	return (
		<ReportsProvider>
			<div className="container-fluid ">
				{activeReport ? (
					<>
						<ReportComponent />
					</>
				) : (
					<>
						<div className="text-center mt-3">
							<h3>Reports</h3>
						</div>
						<div style={{ marginLeft: "auto" }}>
							<FilterReportsComponent />
						</div>
						<div className="reports">
							<ReportsComponent />
						</div>
					</>
				)}
			</div>
		</ReportsProvider>
	);
}
