import { msalInstance } from "../utils/msal-instance"; //Allows us to hook into the connection instance
const { REACT_APP_API_URL } = process.env;

export default class ApiService {
	headers = new Headers();
	get(url) {
		return this.setBearerInHeaders().then(() => {
			return fetch(`${REACT_APP_API_URL}/api${url}`, {
				method: "GET",
				headers: this.headers,
			})
				.then((response) => response.json())
				.catch((err) => {
					console.log(err);
					throw new Error(err);
				});
		});
	}

	setBearerInHeaders() {
		const account = msalInstance.getActiveAccount(); //Grab the logged in user
		if (!account) {
			//Throws error if no user is logged in and set as the active account.
			throw Error(
				"No active account! Verify a user has been signed in and setActiveAccount has been called."
			);
		}

		//Grab the token
		return msalInstance
			.acquireTokenSilent({
				scopes: ["GroupMember.Read.All"], //{scopes: ["GroupMember.Read.All"]]}
				account: account,
			})
			.then((response) => {
				const bearer = `Bearer ${response.accessToken}`;
				this.headers.append("Authorization", bearer);
				this.headers.append("Content-Type", "application/json");
			});
	}
}
