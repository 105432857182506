import { OrganizationProvider } from "./OrganizationContext";
import {
	OrganizationsComponent,
	ManageOrganizationsComponent,
} from "./Organizations";

export const components = {
	OrganizationsComponent,
	ManageOrganizationsComponent,
};

export default OrganizationProvider;
