import {msalConfig} from '../config/auth-config';
import {EventType, PublicClientApplication} from "@azure/msal-browser";


//Take in the Microsoft Authentication Configuration to make this app an instance
export const msalInstance = new PublicClientApplication(msalConfig);

//Grab all of the logged in accounts and make the first one the active account
const accounts = msalInstance.getAllAccounts();
if(accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

//If a user successfully logs into Microsoft Azure, set them as the active account
msalInstance.addEventCallback((event) => {
    if(event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});