import React, { useState } from "react";
import { Link } from "react-router-dom";
import placeholder from "../../../assets/images/organizations/placeholder.png";
import useGlobal from "../../../context/GlobalContext";
import useOrganization from "../OrganizationContext";

export default function OrganizationsComponent() {
	const { setActiveOrganization } = useGlobal();
	const { organizations } = useOrganization();
	// const isAdmin = true;

	const OrganizationCard = ({ organization }) => {
		const [showCheveron, setShowCheveron] = useState(false);
		let appImage = null;
		try {
			appImage =
				require(`./../../../assets/images/organizations/${organization.OrganizationImageTXT}`).default;
		} catch (e) {
			appImage = placeholder;
		}

		const handleOrganizationClick = () => {
			setActiveOrganization(organization);
		};

		return (
			<div
				className="card p-2 card-hover"
				onMouseOver={() => setShowCheveron(true)}
				onMouseOut={() => setShowCheveron(false)}
			>
				<Link
					to={`/reports/${organization.OrganizationID}`}
					style={{ color: "unset", textDecoration: "none" }}
					onClick={handleOrganizationClick}
				>
					<div className="d-flex align-items-center">
						<div
							style={{
								backgroundColor:
									organization.OrganizationLogoTransparentFLG
										? "rgb(91, 91, 91)"
										: "unset",
							}}
						>
							<img
								src={
									organization.OrganizationLogoTXT || appImage
								}
								className="logo-image-small"
								alt={organization.OrganizationNM}
								style={{ opacity: "0.7" }} // Adjust opacity for transparency
							/>
						</div>
						<div className="font-weight-bold ml-2 mr-auto">
							{organization.OrganizationNM}
						</div>
						{/* {isAdmin ? (
							<Link to="/organizations/1">Manage</Link>
						) : (
							""
						)} */}
						{showCheveron ? (
							<div className="justify-self-right">{">"}</div>
						) : (
							""
						)}
					</div>
				</Link>
			</div>
		);
	};
	return (
		<div className="container my-3">
			<div className="text-center mb-3">
				<h3>Choose your organization</h3>
			</div>
			{organizations.map((org) => (
				<OrganizationCard key={org.OrganizationID} organization={org} />
			))}
		</div>
	);
}
