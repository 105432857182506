import { graphConfig, loginRequest } from "../config/auth-config"; //Allows us to use the token created when a user logs in
import { msalInstance } from "./msal-instance"; //Allows us to hook into the connection instance

//Function callMsGraph
//returns graph data of the currently logged in user or throws an error if there is no logged in user.
export async function callMsGraph() {
	const account = msalInstance.getActiveAccount(); //Grab the logged in user
	if (!account) {
		//Throws error if no user is logged in and set as the active account.
		throw Error(
			"No active account! Verify a user has been signed in and setActiveAccount has been called."
		);
	}

	//Grab the token
	const response = await msalInstance.acquireTokenSilent({
		...loginRequest, //{scopes: ["User.Read"]]}
		account: account,
	});

	//setup request header and add token to header
	const headers = new Headers();
	const bearer = `Bearer ${response.accessToken}`;
	headers.append("Authorization", bearer);

	//setup fetch options for a get request and add header with token
	const options = {
		method: "GET",
		headers: headers,
	};

	//GraphMeEndpoint is the fetch URI for getting the graph data for the logged in user.
	return fetch(graphConfig.graphMeEndpoint, options)
		.then((graphMeResponse) => graphMeResponse.json())
		.then((json) => ({ ...json, accessToken: response.accessToken }))
		.catch((error) => console.log(error));
}

export async function getUsers() {
	const account = msalInstance.getActiveAccount(); //Grab the logged in user
	if (!account) {
		//Throws error if no user is logged in and set as the active account.
		throw Error(
			"No active account! Verify a user has been signed in and setActiveAccount has been called."
		);
	}

	//Grab the token
	const response = await msalInstance.acquireTokenSilent({
		...loginRequest, //{scopes: ["User.Read"]]}
		account: account,
	});

	//setup request header and add token to header
	const headers = new Headers();
	const bearer = `Bearer ${response.accessToken}`;
	headers.append("Authorization", bearer);

	//setup fetch options for a get request and add header with token
	const options = {
		method: "GET",
		headers: headers,
	};

	//GraphMeEndpoint is the fetch URI for getting the graph data for the logged in user.
	return fetch(graphConfig.listUsers, options)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function callMsGroups(id) {
	console.log(id, "ID");
	const account = msalInstance.getActiveAccount(); //Grab the logged in user
	if (!account) {
		//Throws error if no user is logged in and set as the active account.
		throw Error(
			"No active account! Verify a user has been signed in and setActiveAccount has been called."
		);
	}

	//Grab the token
	const response = await msalInstance.acquireTokenSilent({
		scopes: ["GroupMember.Read.All"], //{scopes: ["GroupMember.Read.All"]]}
		account: account,
	});

	console.log(response, "Group Response");

	//setup request header and add token to header
	const headers = new Headers();
	const bearer = `Bearer ${response.accessToken}`;
	headers.append("Authorization", bearer);
	headers.append("Content-Type", "application/json");
	const body = { securityEnabledOnly: false };

	// Setup fetch options with POST request
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(body),
	};

	// Returns all Groups that the user is a member of
	return fetch(graphConfig.getGroups(id), options)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function getIDToken() {
	const account = msalInstance.getActiveAccount(); //Grab the logged in user
	if (!account) {
		//Throws error if no user is logged in and set as the active account.
		throw Error(
			"No active account! Verify a user has been signed in and setActiveAccount has been called."
		);
	}
	const response = await msalInstance.acquireTokenSilent({
		...loginRequest, //{scopes: ["User.Read"]]}
		account: account,
	});

	return response.idToken;
}

export async function inviteUserEmail(email, groupID) {
	const account = msalInstance.getActiveAccount(); //Grab the logged in user
	if (!account) {
		//Throws error if no user is logged in and set as the active account.
		throw Error(
			"No active account! Verify a user has been signed in and setActiveAccount has been called."
		);
	}

	//Grab the token
	const response = await msalInstance.acquireTokenSilent({
		scopes: ["User.Invite.All"],
		account: account,
	});

	//setup request header and add token to header
	const headers = new Headers();
	const bearer = `Bearer ${response.accessToken}`;
	headers.append("Authorization", bearer);
	headers.append("Content-Type", "application/json");
	const invitation = {
		invitedUserEmailAddress: email,
		inviteRedirectUrl: "https://bridgeway.xantiedev.com",
	};

	// Setup fetch options with POST request
	const invitationOptions = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(invitation),
	};

	return fetch(graphConfig.createInvite, invitationOptions)
		.then((response) => response.json())
		.then((json) => {
			// const { id } = json;
			console.log(json, "Invited Guest Response");

			// const addMemberOptions = {
			//     method: "POST",
			//     headers: headers,
			//     body: JSON.stringify({
			//         "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${id}`
			//     })
			// }

			// return fetch(graphConfig.addMemberToGroup(groupID), addMemberOptions)
			//     .then(response => response.json())
		})
		.catch((error) => console.log(error, "Invited Guest Error"));
}
