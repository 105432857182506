import { NavigationClient } from "@azure/msal-browser";

//Class CustomNavigationClient
//@param history: a React-Router-Dom history from the useHistory hook.
//From the @azure samples for a react-router-dom project, this class will override the
//default function MSAL uses to navigate to other URLs in our app.
export class CustomNavigationClient extends NavigationClient {
	constructor(history) {
		super();
		this.history = history;
	}

	//Uses the react-router-dom useHistory hook to allow client side routing.
	async navigateInternal(url, options) {
		const relativePath = url.replace(window.location.origin, "");
		if (options.noHistory) {
			this.history(relativePath, { replace: true });
		} else {
			this.history.push(relativePath, { replace: true });
		}

		return false;
	}
}
