import { Routes, Route } from "react-router-dom";
import { GlobalProvider } from "../context/GlobalContext";
import NavigationBar from "../ui/navigation";
// import ManageOrganization from "./ManageOrganization";
import SelectOrganizationPage from "./SelectOrganization";
import ViewOrganizationReportsPage from "./ViewOrganizationReports";
const packageJSON = require("../../../package.json");

export default function PageRouter() {
	return (
		<GlobalProvider>
			<NavigationBar version={packageJSON.version} />

			<Routes>
				<Route
					path="/reports/:OrganizationID"
					element={<ViewOrganizationReportsPage />}
				/>
				{/* <Route
					path={"/organizations/:OrganizationID"}
					element={<ManageOrganization />}
				/> */}
				<Route path="/" element={<SelectOrganizationPage />} />
				{/* <Route path="/" element={<App />} /> */}
				{/* <Route path="expenses" element={<Expenses />} /> */}
				{/* <Route path="invoices" element={<Invoices />} /> */}
			</Routes>
		</GlobalProvider>
	);
}
