import { createContext, useContext, useState } from "react";
// import { useAuth } from "../../context/auth-context";

const GlobalContext = createContext();

export default function useGlobal() {
	return useContext(GlobalContext);
}

export function GlobalProvider({ children }) {
	const [activeOrganization, setActiveOrganization] = useState(null);
	const [activeOrganizationID, setActiveOrganizationID] = useState(null);
	const [activeReport, setActiveReport] = useState(null);

	return (
		<GlobalContext.Provider
			value={{
				activeOrganization,
				activeOrganizationID,
				activeReport,
				setActiveReport,
				setActiveOrganization,
				setActiveOrganizationID,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
}
