import React from "react";
import useGlobal from "../../../context/GlobalContext";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
export default function ReportTileComponent({ report }) {
	const { setActiveReport } = useGlobal();

	const embededConfig = ({ reportId, embedUrl, accessToken }) => {
		return {
			type: "report", // Supported types: report, dashboard, tile, visual and qna
			id: reportId,
			embedUrl,
			accessToken: accessToken,
			tokenType: models.TokenType.Embed,
			settings: {
				panes: {
					filters: {
						expanded: false,
						visible: false,
					},
				},
				// background: models.BackgroundType.Transparent,
			},
		};
	};

	return (
		<div
			className={
				report.hiddenFLG ? "d-none" : "card report-tile card-hover ml-3"
			}
			onClick={() => {
				setActiveReport(report);
			}}
		>
			<div className="card-body card-overlay">
				<div className="card-title">{report.reportName}</div>
				<PowerBIEmbed
					embedConfig={embededConfig(report)}
					eventHandlers={
						new Map([
							// [
							// 	"loaded",
							// 	function () {
							// 		console.log("Report loaded");
							// 	},
							// ],
							// [
							// 	"rendered",
							// 	function () {
							// 		console.log("Report rendered");
							// 	},
							// ],
							[
								"error",
								function (event) {
									console.log(event.detail);
								},
							],
						])
					}
					cssClassName={"none"}
				/>
			</div>
		</div>
	);
}
